import React from "react";
import { Link } from 'react-router-dom';
import './navbar.css';
import hbImg from '../../Images/hb2.svg';
// import arrowDown from '../../Images/SliderImages/Images/arrow-down.svg';
import ddImg from '../../Images/ddImg.png';
import dablerLogo from "../../../CommonImages/dabler4.png";
import dcDoc from "../../../Files/dcdoc.pdf";

export default function Navbar(props){

    const [scrolled, setScrolled] = React.useState(window.scrollY);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    // console.log(windowWidth)
    
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    const [showSubItems, setShowSubItems] = React.useState(false);

    function handleShowSubItems(){
       setShowSubItems(!showSubItems);
    }

    const [showDropdown, setShowDropdown] = React.useState(false)
    function handleDropdown(){
        if(showDropdown){
            setShowSubItems(false)
        }
        setShowDropdown(!showDropdown);
    }
    
    if(windowWidth > 1086){
        if(showDropdown || showSubItems){
            setShowDropdown(false);
            setShowSubItems(false);
        }
    }

    //To handle the scroll
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        let navbar = document.getElementById('navbar');
        const links = document.querySelectorAll('.dc-nav-each-option > a, .right-section > a');
        // Loop through each selected <a> element and apply the style

        if(scrolled > 20) {
            navbar.classList.add('scrolled');

            links.forEach(link => {
                link.style.color = '#3f3f3f';
            });
        }
        else{
             navbar.classList.remove('scrolled');
             links.forEach(link => {
                link.style.color = '#3f3f3f';
            });
            };
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [scrolled])


    const handleScroll = () => {
       let isScrolled = window.scrollY;
       if(isScrolled !== scrolled){
        setScrolled(isScrolled);
       }
    }


    return (
        <>
         <div className="nav-top-section" id="navbar"  > 
            <div className="name-logo-container">
                <img src={dablerLogo} alt="logo" style={{maxWidth: "200px"}}/>
            </div>

            <div className="dc-nav-options">

                <div className="dc-nav-each-option">
                    <Link to={"/"} className="dc-nav-a" >Home</Link>
                </div>

                <div className="dc-nav-each-option"> 
                    <a href="#" className="dc-nav-a" >Categories</a>
                    <div className="dc-dd-container">
                        <a href={dcDoc} target="_blank" className="dc-dd-items">Documentation</a>
                        {/* <a href="#" className="dc-dd-items">Blogs</a>
                        <a href="#" className="dc-dd-items">Developers</a> */}
                    </div>
                </div>

                {/* <div className="dc-nav-each-option">
                    <a href="#" className="dc-nav-a">Pricing</a>
                </div>

                <div className="dc-nav-each-option">
                    <a href="#" className="dc-nav-a">Try Free</a>
                </div>   */}

                <div className="dc-nav-each-option">
                    <a href="#contact-us" className="dc-nav-a">Contact Us</a>
                </div>     

            </div>

            <div className="right-section">
                <a href="#" className="dc-nav-a" style={{visibility: "hidden"}}>Login</a> 
            </div>

            <div className="hamburger-menu">
                <img src={hbImg} alt="hb" className="hb-icon" onClick={handleDropdown}/>
            </div>

            <div className={`hamburger-menu-dd-top ${showDropdown ? 'show-hb-dd' : 'hide-hb-dd'}`}>
                <div className="hb-menu-dd-items" onClick={handleShowSubItems}>
                    <div className="hb-menu-dd-item-name" >Categories <img src={ddImg} alt="arr-down" className="arr-down-img" /></div>
                    <div className={`hb-menu-dd-sub-items ${showSubItems ? 'show-sub-items' : 'hide-sub-items'}`}>
                        <a href="#" className="dd-sub-item">Documentation</a>
                        {/* <a href="#" className="dd-sub-item">Blogs</a>
                        <a href="#" className="dd-sub-item">Developers</a> */}
                    </div>
                </div>

                
                {/* <div className="hb-menu-dd-items" >
                    <div className="hb-menu-dd-item-name">Services <img src={ddImg} alt="arr-down" className="arr-down-img" /></div>
                    <div className="hb-menu-dd-sub-items">

                    </div>
                </div> */}

                <div className="hb-menu-dd-items" >
                    <Link className="hb-menu-dd-item-name" to={"/"}>Home</Link>
                    <div className="hb-menu-dd-sub-items">

                    </div>
                </div>

                <div className="hb-menu-dd-items" >
                    <a href="#contact-us" style={{textDecoration : "none"}}  onClick={handleDropdown}><div className="hb-menu-dd-item-name">Contact Us </div></a>
                </div>

            </div>
         </div>
         
        </>
    )
}